import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 467.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,467.000000) scale(0.100000,-0.100000)">

<path d="M2828 3169 c-22 -12 -24 -62 -3 -79 11 -9 15 -34 15 -96 l0 -84 -197
0 c-218 0 -245 -6 -287 -63 -19 -26 -21 -45 -24 -218 l-3 -189 -78 0 c-59 0
-82 4 -91 16 -16 19 -59 15 -73 -7 -14 -23 0 -67 24 -74 11 -4 30 0 44 9 21
14 63 16 268 16 236 0 245 1 250 20 5 18 14 20 85 20 86 0 109 12 97 50 -6 18
-15 20 -85 20 l-78 0 -4 33 c-3 17 -8 40 -13 50 -7 16 8 17 236 17 224 0 247
-2 276 -20 33 -20 50 -44 63 -92 12 -41 -34 -310 -63 -368 -26 -51 -85 -97
-143 -110 -54 -13 -391 -12 -415 0 -34 19 -20 29 43 32 51 2 63 6 66 21 9 45
-4 47 -291 47 -239 0 -273 2 -289 17 -14 13 -25 15 -46 7 -22 -8 -27 -15 -27
-44 0 -29 5 -36 27 -44 21 -8 32 -6 46 7 14 13 37 17 95 17 l77 0 0 -125 c0
-134 7 -161 52 -202 37 -34 93 -43 281 -43 l177 0 0 -64 c0 -45 -4 -67 -16
-76 -37 -31 7 -97 54 -79 26 10 31 59 7 79 -10 9 -15 32 -15 76 l0 64 55 0 55
0 0 -32 c0 -18 -7 -42 -15 -52 -22 -29 -18 -53 9 -71 28 -19 61 -8 71 24 6 21
-3 51 -16 51 -5 0 -9 18 -9 39 l0 39 198 4 c182 3 200 5 232 25 69 43 75 64
78 286 l4 197 63 0 c43 0 66 -5 75 -15 18 -21 56 -19 73 6 18 25 11 56 -15 70
-20 11 -58 3 -58 -12 0 -5 -31 -9 -70 -9 l-70 0 0 50 0 50 125 0 c99 0 131 -3
150 -16 23 -15 27 -15 50 0 27 18 31 36 15 67 -13 23 -52 25 -70 4 -10 -12
-39 -15 -140 -15 l-129 0 -3 204 c-3 227 -5 234 -78 284 -31 21 -42 22 -231
22 l-199 0 0 59 c0 40 5 63 15 71 20 17 19 43 -3 63 -24 22 -45 21 -67 -3 -18
-20 -15 -70 5 -70 6 0 10 -27 10 -61 l0 -60 -52 3 -53 3 -3 81 c-2 63 1 84 13
94 22 18 19 67 -4 80 -24 12 -31 12 -53 -1z m50 -43 c-2 -13 -10 -21 -23 -21
-23 0 -33 34 -14 45 20 13 40 -1 37 -24z m141 -45 c16 -28 -14 -51 -36 -28
-13 13 -14 19 -3 31 17 21 27 20 39 -3z m-871 -659 c3 -18 -1 -22 -22 -22 -19
0 -26 5 -26 18 0 39 43 43 48 4z m1707 -37 c0 -13 -8 -21 -22 -23 -17 -3 -23
2 -23 16 0 10 3 22 7 26 13 13 38 1 38 -19z m-125 -145 c0 -24 -26 -38 -42
-22 -16 16 -2 42 22 42 13 0 20 -7 20 -20z m-1580 -140 c0 -10 -6 -20 -12 -23
-21 -7 -38 4 -38 23 0 10 6 20 13 23 20 7 37 -4 37 -23z m868 -508 c3 -16 -2
-22 -17 -22 -26 0 -36 17 -20 36 16 19 33 13 37 -14z m-140 -55 c4 -20 -25
-34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z"/>
<path d="M4090 2329 l0 -370 278 3 c248 3 281 5 312 22 49 26 79 55 103 101
20 36 22 59 25 215 5 214 -4 261 -59 323 -63 72 -88 77 -396 77 l-263 0 0
-371z m551 212 l29 -29 0 -172 c0 -187 -7 -216 -55 -238 -17 -8 -88 -12 -205
-12 l-180 0 0 240 0 240 191 0 191 0 29 -29z"/>
<path d="M5470 2580 l0 -70 -35 0 -35 0 0 -55 0 -55 35 0 35 0 0 -160 c0 -139
3 -166 19 -199 31 -60 62 -74 169 -79 l92 -4 0 55 0 56 -67 3 -68 3 -3 163 -2
162 85 0 85 0 0 55 0 55 -85 0 -85 0 0 70 0 70 -70 0 -70 0 0 -70z"/>
<path d="M4943 2490 c-40 -24 -62 -57 -70 -104 l-6 -36 65 0 c60 0 66 2 76 25
12 25 13 26 119 23 l108 -3 3 -52 3 -53 -138 0 c-157 0 -191 -10 -230 -69 -18
-26 -23 -48 -23 -96 0 -48 5 -70 23 -96 36 -55 76 -69 197 -69 104 0 104 0
137 32 l33 32 0 -32 0 -32 65 0 65 0 0 226 c0 210 -2 229 -20 259 -34 55 -69
65 -232 65 -124 0 -147 -3 -175 -20z m297 -353 c0 -24 -5 -48 -12 -55 -8 -8
-49 -12 -118 -12 -117 0 -130 6 -130 55 0 50 12 55 142 55 l118 0 0 -43z"/>
<path d="M5891 2493 c-41 -20 -71 -67 -71 -110 l0 -33 64 0 c59 0 65 2 68 23
3 21 7 22 113 22 l110 0 3 -52 3 -53 -133 0 c-110 0 -140 -4 -176 -20 -75 -34
-103 -138 -62 -228 28 -63 76 -81 206 -82 101 0 102 0 133 32 l31 32 0 -32 0
-32 70 0 70 0 0 218 c0 243 -5 267 -69 309 -31 21 -45 23 -180 23 -116 0 -153
-4 -180 -17z m287 -365 l-3 -53 -100 -3 c-139 -4 -145 -2 -145 52 0 26 5 46
13 49 6 3 63 6 125 6 l113 1 -3 -52z"/>
<path d="M2330 2260 l0 -70 150 0 c127 0 150 2 150 15 0 8 9 24 20 35 19 19
33 20 204 20 158 0 185 2 190 16 3 8 1 24 -4 35 -10 18 -25 19 -360 19 l-350
0 0 -70z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
